<template>
    <div class="list-item">
        <!-- 主要内容 -->
        <div class="list-item-main">
            <!-- 图片 -->
            <div class="list-item-main-cover">
                <el-image
                    :src="itemPic"
                    fit="scale-down"
                    class="list-item-main-cover-pic">
                    <!-- 加载中样式 -->
                    <template v-slot:placeholder>
                        <div class="image-slot">
                            <i class="el-icon-loading"></i>
                        </div>
                    </template>
                    <!-- 加载失败样式 -->
                    <template v-slot:error>
                        <div class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </template>
                </el-image>
            </div>
            <!-- 简介 -->
            <div class="list-item-main-info">
                <slot name="info"></slot>
            </div>
        </div>
        <!-- 处理 -->
        <div class="list-item-handler">
            <!-- 编辑 -->
            <router-link 
                :to="{
                    name: editRouter,
                    query: {
                        id: itemId
                    }
                }">
                编辑详情
            </router-link>
            <!-- 分隔 -->
            <span>|</span>
            <!-- 删除 -->
            <a href="javascript:;"
                @click="handleDel">
                删除
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            itemPic: String,
            itemId: [ Number, String ],
            editRouter: {
                type: String,
                default: ""
            }
        },
        methods: {
            handleDel(){
                this.$emit('handleDelete')
            }
        },
    }
</script>

<style scoped>
.list-item{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 1px solid #F2F6FC;
    border-radius: 2px;
}

/* 主要内容 */
.list-item-main{
    display: flex;
    justify-content: space-between;
    padding: 14px;
}
/* 图片 */
.list-item-main-cover{
    position: relative;
    width: 30%;
    margin-right: 14px;
}
.list-item-main-cover::before{
    display: block;
    content: "";
    padding-bottom: 100%;
}
.list-item-main-cover-pic{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
/*  */
.list-item-main-info{
    flex: 1;
}

/* 处理 */
.list-item-handler{
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #F2F6FC;
}
.list-item-handler > a{
    width: 40%;
    text-align: center;
    color: #909399;
}
.list-item-handler > a:hover{
    color: #8d9afc;
}
</style>